var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discovery-toolbar"},[_c('SearchBox',{model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('div',{staticClass:"search-results"},[_c('Collections',{staticClass:"category",attrs:{"collections":_vm.collections,"searchTerm":_vm.searchTerm,"offerDetails":false,"collapsed":_vm.collapsed},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"discovery-entity",attrs:{"draggable":_vm.supportsLoadCollection},on:{"dragstart":function($event){return _vm.onDrag($event, 'collection', item)}}},[_c('div',{staticClass:"discovery-info",on:{"click":function($event){return _vm.showCollectionInfo(item.id)}}},[_c('strong',{attrs:{"title":item.id}},[_vm._v(_vm._s(item.id))]),(item.title)?_c('small',{attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))]):_vm._e()]),(_vm.hasCollectionPreview(item))?_c('button',{staticClass:"discovery-button",attrs:{"type":"button","title":"View on map"},on:{"click":function($event){return _vm.showCollectionPreview(item)}}},[_c('i',{staticClass:"fas fa-map"})]):_vm._e()])]}}])}),_c('Processes',{staticClass:"category",attrs:{"processes":_vm.allProcesses,"searchTerm":_vm.searchTerm,"offerDetails":false,"collapsed":_vm.collapsed},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"discovery-entity",attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.onDrag($event, 'process', item)}}},[_c('div',{staticClass:"discovery-info",on:{"click":function($event){return _vm.showProcess(item)}}},[(item.namespace === 'user')?_c('i',{staticClass:"custom-process fas fa-xs fa-sitemap",attrs:{"title":"Custom Process"}}):(item.namespace !== 'backend')?_c('i',{staticClass:"custom-process fas fa-xs fa-tag",attrs:{"title":("Process from namespace '" + (item.namespace) + "'")}}):_vm._e(),_c('strong',{attrs:{"title":item.id}},[_vm._v(_vm._s(item.id))]),(item.summary)?_c('small',{attrs:{"title":item.summary}},[_vm._v(_vm._s(item.summary))]):_vm._e()])])]}}])}),(_vm.hasUdfRuntimes)?_c('UdfRuntimes',{staticClass:"category",attrs:{"runtimes":_vm.udfRuntimes,"searchTerm":_vm.searchTerm,"offerDetails":false,"collapsed":_vm.collapsed},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var summary = ref.summary;
var item = ref.item;
return [_c('div',{staticClass:"discovery-entity",attrs:{"draggable":_vm.supportsRunUdf},on:{"dragstart":function($event){return _vm.onDrag($event, 'udf', {runtime: summary.identifier, version: item.default})}}},[_c('div',{staticClass:"discovery-info",on:{"click":function($event){return _vm.showUdfInfo(summary.identifier, item)}}},[_c('strong',{attrs:{"title":summary.identifier}},[_vm._v(_vm._s(summary.identifier)+" ("+_vm._s(item.default)+")")]),(summary.summary)?_c('small',{attrs:{"title":summary.summary}},[_vm._v(_vm._s(summary.summary))]):_vm._e()])])]}}],null,false,92063951)}):_vm._e(),_c('FileFormats',{staticClass:"category",attrs:{"formats":_vm.fileFormats,"showInput":false,"heading":"Export File Formats","searchTerm":_vm.searchTerm,"offerDetails":false,"collapsed":_vm.collapsed},scopedSlots:_vm._u([{key:"summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"discovery-entity",attrs:{"draggable":_vm.supportsSaveResult},on:{"dragstart":function($event){return _vm.onDrag($event, 'fileformat', item)}}},[_c('div',{staticClass:"discovery-info",on:{"click":function($event){return _vm.showFileFormatInfo(item)}}},[_c('strong',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),(item.title)?_c('small',{attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))]):_vm._e()])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }